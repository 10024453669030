import '../scss/style.scss';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.video-modal')) {
    const html = document.querySelector('html');
    const trigger = document.querySelector('.video-modal-trigger');
    const modal = document.querySelector('.video-modal');
    const modalContainer = document.querySelector('.video-modal .video-modal-container');
    const modalBg = document.querySelector('.video-modal .video-modal-bg');
    const modalClose = document.querySelectorAll('.video-modal .video-modal-close, .video-modal .video-modal-overlay');
    const modalPlay = document.querySelector('.video-modal .video-modal-play');

    trigger.addEventListener('click', (e) => {
      e.preventDefault();
      html.classList.add('lock');
      modal.classList.add('active');
      modalContainer.classList.add('active');

      let video_id = modalContainer.dataset.id;
      video_id = extractYouTubeVideoId(video_id);
      modal.querySelector('iframe').setAttribute('src', `https://www.youtube.com/embed/${video_id}`);
    });

    function extractYouTubeVideoId(url) {
      const regexps = [
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/,
        /^.*youtube\.com\/shorts\/([^#\&\?]*).*/
      ];

      for (const regex of regexps) {
        const match = url.match(regex);
        if (match && match[7]) {
          return match[7];
        } else if (match && match[1]) {
          return match[1];
        }
      }

      return null;
    }

    modalClose.forEach(close => {
      close.addEventListener('click', (e) => {
        e.preventDefault();
        html.classList.remove('lock');
        modal.classList.remove('active');
        modalContainer.classList.remove('active');
        modal.querySelector('iframe').setAttribute('src', '');
        modalBg.classList.remove('active');
        modalPlay.classList.remove('active');
      });
    });

    modalPlay.addEventListener('click', () => {
      modalBg.classList.add('active');
      modalPlay.classList.add('active');

      let src = modal.querySelector('iframe').getAttribute('src');
      modal.querySelector('iframe').setAttribute('src', `${src}?autoplay=1`);
    });
  }

  if (document.querySelector('.slider-home')) {
    const swiper1 = new Swiper('.slider-home', {
      loop: false,
      spaceBetween: 20,
      breakpoints: {
        '0': {
          slidesPerView: 1.2
        },
        '680': {
          slidesPerView: 1.7
        },
        '1000': {
          slidesPerView: 2.7,
        }
      },
    });
  }

  if (document.querySelector('.filter-slider-results')) {
    const swiper2 = new Swiper('.filter-slider-results', {
      modules: [Navigation, Pagination],
      loop: false,
      spaceBetween: 23,
      navigation: {
        prevEl: '.filter-slider-prev',
        nextEl: '.filter-slider-next',
      },
      breakpoints: {
        '0': {
          slidesPerView: 1
        },
        '680': {
          slidesPerView: 2
        },
        '1000': {
          slidesPerView: 3,
          pagination: {
            el: '.filter-slider-pagination',
            clickable: true,
          },
        }
      },
    });

    const html = document.querySelector('html');
    const modalTrigger = document.querySelectorAll('.modal-trigger');
    const modals = document.querySelectorAll('.filter-slider-modal');

    modalTrigger.forEach(trigger => {
      trigger.addEventListener('click', function (e) {
        e.preventDefault();
        let modalID = trigger.dataset.modal;

        modals.forEach(modal => {
          if (modal.id === `modal-${modalID}`) {
            html.classList.add('lock');
            modal.classList.add('open');
          }

          const closeButtons = modal.querySelectorAll('.close-overlay, .close-popup');
          closeButtons.forEach(button => {
            button.addEventListener('click', (e) => {
              e.preventDefault();
              html.classList.remove('lock');
              modal.classList.remove('open');
            });
          });
        });
      });
    });
  }

  if (document.querySelector('.accordion-item')) {
    const triggers = document.querySelectorAll('.accordion-item .open-accordion, .accordion-item .close-accordion');

    triggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        trigger.closest('.accordion-item').classList.toggle('active');
      });
    });
  }

  if (document.querySelector('.filter-slider-laws')) {
    const swiper2 = new Swiper('.filter-slider-laws', {
      modules: [Navigation],
      loop: false,
      spaceBetween: 16,
      navigation: {
        prevEl: '.filter-slider-laws-prev',
        nextEl: '.filter-slider-laws-next',
      },
      breakpoints: {
        '0': {
          slidesPerView: 1.2
        },
        '680': {
          slidesPerView: 2.2
        },
        '1000': {
          slidesPerView: 3.2,
        },
        '1200': {
          slidesPerView: 4.2,
        }
      },
    });

    const html = document.querySelector('html');
    const modalTrigger = document.querySelectorAll('.modal-trigger');
    const modals = document.querySelectorAll('.filter-slider-laws-modal');

    modalTrigger.forEach(trigger => {
      trigger.addEventListener('click', function (e) {
        e.preventDefault();
        let modalID = trigger.dataset.modal;

        modals.forEach(modal => {
          if (modal.id === `modal-${modalID}`) {
            html.classList.add('lock');
            modal.classList.add('open');
          }

          const closeButtons = modal.querySelectorAll('.close-overlay, .close-popup');
          closeButtons.forEach(button => {
            button.addEventListener('click', (e) => {
              e.preventDefault();
              html.classList.remove('lock');
              modal.classList.remove('open');
            });
          });
        });
      });

      const swiper3 = new Swiper('.laws-gallery-slider', {
        modules: [Pagination],
        loop: false,
        slidesPerView: 1,
        pagination: {
          el: '.laws-gallery-slider-pagination',
          clickable: true,
        },
        on: {
          slideChange: function () {
            this.pagination.render();
            this.pagination.update();
          }
        }
      });
    });
  }

  if (document.querySelector('.tabs')) {
    const triggers = document.querySelectorAll('.tab-trigger');

    triggers.forEach(trigger => {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        triggers.forEach(t => t.classList.remove('active'));
        document.querySelectorAll('[id^="tab-"]').forEach(tab => tab.classList.remove('active'));
        document.querySelectorAll('li.has-subitems').forEach(li => li.classList.remove('active'));

        trigger.classList.add('active');

        const closestLi = trigger.closest('li.has-subitems');
        if (closestLi) {
          closestLi.classList.add('active');
        }

        let dataTab = trigger.dataset.tab;
        const tabElement = document.getElementById('tab-' + dataTab);
        if (tabElement) {
          tabElement.classList.add('active');
        }
      });
    });
  }

  const searchBtn = document.querySelector('.search-btn');
  const capClose = document.querySelector('.cap-close');
  const modalSearch = document.querySelector('.modal-search');

  searchBtn.addEventListener('click', () => {
    modalSearch.classList.remove('hidden');
    modalSearch.classList.add('block');
  });

  capClose.addEventListener('click', () => {
    modalSearch.classList.remove('block');
    modalSearch.classList.add('hidden');
  });

  const openNav = document.querySelector('.open-navigation');
  const navMenu = document.querySelector('.nav-mobile-menu');
  const openMobile = document.querySelector('.open-mobile-navigation');
  const closeMobile = document.querySelector('.close-navigation');

  openNav.addEventListener('click', function () {
    navMenu.classList.add('max-[960px]:block');
    navMenu.classList.remove('max-[960px]:hidden');
  });

  openMobile.addEventListener('click', function () {
    const parent = this.closest('a');
    parent.classList.toggle('navigation-mobile');
  });

  closeMobile.addEventListener('click', function () {
    this.parentNode.classList.add('max-[960px]:hidden');
    this.parentNode.classList.remove('max-[960px]:block');
  });

  if (document.querySelector('#filtered-results')) {
    document.querySelector('#filtered-results').addEventListener('change', function (event) {
      event.preventDefault();

      let selects = document.querySelectorAll('select');
      let selectedValues = [];

      selects.forEach(function (select) {
        let value = select.value;
        if (value) {
          selectedValues.push(value);
        }
      });

      if (selectedValues.length > 0) {
        let queryString = selectedValues.map(id => 'pro[]=' + id).join('&');
        let url = window.location.origin + window.location.pathname + '?' + queryString;
        window.location.href = url + '#filtered-results';
      }
    });
  }

  if (document.querySelector('#filtered-results-accordion')) {
    document.querySelector('#filtered-results-accordion').addEventListener('change', function (event) {
      event.preventDefault();

      let selects = document.querySelectorAll('select');
      let selectedValues = [];

      selects.forEach(function (select) {
        let value = select.value;
        if (value) {
          selectedValues.push(value);
        }
      });

      if (selectedValues.length > 0) {
        let queryString = selectedValues.map(id => 'acc[]=' + id).join('&');
        let url = window.location.origin + window.location.pathname + '?' + queryString;
        window.location.href = url + '#filtered-results-accordion';
      }
    });
  }

  if (document.querySelector('#filtered-slider-laws')) {
    document.querySelector('#filtered-slider-laws').addEventListener('change', function (event) {
      event.preventDefault();

      let selects = document.querySelectorAll('select');
      let selectedValues = [];

      selects.forEach(function (select) {
        let value = select.value;
        if (value) {
          selectedValues.push(value);
        }
      });

      if (selectedValues.length > 0) {
        let queryString = selectedValues.map(id => 'laws[]=' + id).join('&');
        let url = window.location.origin + window.location.pathname + '?' + queryString;
        window.location.href = url + '#filtered-slider-laws';
      }
    });
  }
});